namespace ConfigV2 {
  export const drillModules = {
    // 自己採点縦
    SELF_VR: ['TitleWithPenSwitcher', 'QuestionImageVertical', 'Transition'],
    // 自己採点横
    SELF_HR: ['TitleWithPenSwitcher', 'QuestionImageHorizontal', 'Transition'],

    // 全自己採点
    SCORING_ALL: ['Title', 'CorrectGuid', 'Correct', 'Transition'],

    // 全自己採点結果
    RESULT_ALL: ['Title', 'ResultGuide', 'Correct', 'Transition'],

    // 全自己採点結果（理解度表示あり）
    RESULT_ALL_PREDICTED_SCORE: ['Title', 'ResultGuide', 'PredictedScoreBar', 'Correct', 'Transition'],
  }
}

export default ConfigV2
