





import { Component, Mixins, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import DrillWebApi from '@/mixins/drillsv2/WebApi'
import DrillTransition from '@/mixins/drillsv2/DrillTransition'
import { PAGE_TYPE } from '@/store/modules/DrillsV2'

@Component({
  components: {
    ButtonBase,
  },
})
export default class Transition extends Mixins(DrillWebApi, DrillTransition) {
  private classModeCode = ''
  private classCategoryCode = ''
  private subjectCode = ''

  private isV3 = Vue.prototype.$gdlsCookiesV3.isV3()

  /**
   * 左側(前へ)ボタンのテキスト
   */
  private get prevText(): string {
    let text = ''

    switch (this.pageType) {
      case PAGE_TYPE.DRILL:
      case PAGE_TYPE.SCORING_UNIT:
      case PAGE_TYPE.RESULT_UNIT:
        text = '中断する'
        break
    }

    return text
  }

  /**
   * 左側(前へ)ボタン押下時の遷移先
   */
  private get prevUrl(): string {
    let url = ''

    switch (this.pageType) {
      case PAGE_TYPE.DRILL:
      case PAGE_TYPE.SCORING_UNIT:
        if (this.classModeCode === 'AI' || this.classModeCode === 'MN') {
          // AI学習の場合、演習結果画面へ移行する
          url = this.urlResultAll
        } else {
          // 授業モードごとの一覧画面に遷移
          url = this.urlCurriculumList(this.classModeCode)
        }
        break
      case PAGE_TYPE.RESULT_UNIT:
        // 設問ごとの結果画面から中断した場合、演習の結果画面を一度挟む
        url = this.urlResultAll
        break
    }

    return url
  }

  /**
   * 右側(次へ)ボタンのテキスト
   */
  private get nextText(): string {
    let text = ''

    switch (this.pageType) {
      case PAGE_TYPE.DRILL:
        text = '答え合わせをする'
        break
      case PAGE_TYPE.SCORING_UNIT:
        text = '結果を登録する'
        break
      case PAGE_TYPE.RESULT_UNIT:
        if (this.$store.getters['drills/endCondition'].left == 1 && this.isV3) {
          text = '結果一覧へ'
        } else {
          text = '次の問題へ'
        }
        break
      case PAGE_TYPE.RESULT_ALL:
        switch (this.classModeCode) {
          case 'AI':
            text = '学習内容選択へ戻る'
            break
          default:
            text = '項目リストへ戻る'
            break
        }
        break
    }

    return text
  }

  /**
   * 右側(次へ)ボタン押下時の遷移先
   */
  private get nextUrl(): string {
    let url = ''

    switch (this.pageType) {
      case PAGE_TYPE.DRILL:
        url = this.urlScoring
        break
      case PAGE_TYPE.SCORING_UNIT:
        url = this.afterScoringUnit
        break
      case PAGE_TYPE.RESULT_UNIT:
        url = this.urlDrill
        break
      case PAGE_TYPE.RESULT_ALL:
        url = this.urlCurriculumList(this.classModeCode)
        break
    }

    return url
  }

  /**
   * 採点画面後の遷移先URL取得
   */
  private get afterScoringUnit(): string {
    let url = ''
    switch (this.classModeCode) {
      case 'MN':
        // 間違い直しの場合は問題ごとの結果画面を出さないため次の問題へ
        url = this.urlDrill
        break
      case 'AI':
        // AI学習の場合は問題ごとの結果画面へ
        url = this.urlResultUnit
    }

    return url
  }

  protected mounted(): void {
    Vue.prototype.$logger.info('-- Transition mounted')
    this.classModeCode = this.$store.getters['drillsV2/classModeCode']
    this.classCategoryCode = this.$store.getters['drillsV2/classCategoryCode']
    this.subjectCode = this.$store.getters['drillsV2/subjectCode']
  }

  /**
   * 前ページへ遷移
   */
  private async prevPage() {
    Vue.prototype.$logger.info('-- Transition prevPage')

    Vue.prototype.$loading.start()

    // 規定の遷移先ページ取得
    let prevUrl = this.prevUrl
    const isModeAi = this.classModeCode === 'AI'
    const isModeCorrectMistake = this.classModeCode === 'MN'
    // AI学習モードの場合、中断時にドリル終了処理を実行する
    if (isModeAi || isModeCorrectMistake) {
      // ドリル終了処理
      const status = await this.completeDrillApi(this.resultDrillId, isModeAi)
      if (this.statusDeleted(status)) {
        // ドリルが削除されていた場合、遷移先を一覧画面に変更
        prevUrl = this.urlCurriculumList(this.classModeCode)
      }
    }

    Vue.prototype.$loading.complete()

    // 画面遷移
    this.$router.push({
      path: prevUrl,
    })
  }

  /**
   * 次ページへ遷移
   */
  private async nextPage() {
    Vue.prototype.$logger.info('-- Transition nextPage')

    Vue.prototype.$loading.start()

    switch (this.pageType) {
      case PAGE_TYPE.SCORING_UNIT:
        // 採点実施
        if (!(await this.scoringUnit())) {
          // 入力に問題がある場合は終了
          return
        }
        break
      case PAGE_TYPE.RESULT_ALL:
        // 完了画面
        // 教科コードを更新
        await this.setSubjectCode()
        break
    }

    Vue.prototype.$loading.complete()

    // 画面遷移
    this.$router.push({
      path: this.nextUrl,
    })
  }

  /**
   * 教科コードのcookieセット
   */
  private async setSubjectCode() {
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie['subjectCode'] = this.subjectCode
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }

  /**
   * 自己採点実施(大問単位)
   *  入力に問題がある場合はfalseを返す
   *
   * @returns 入力チェック結果
   */
  private async scoringUnit(): Promise<boolean> {
    const correctInput = this.$store.getters['drillsV2/corrects']
    // 入力内容チェック
    const correctCheck = correctInput.flat()
    if (correctCheck.includes(null)) {
      alert('全ての問題で○×をつけましょう！')
      Vue.prototype.$loading.complete()
      return false
    }

    // 現在ページ取得
    const page = Number(this.$route.query.page)

    // 結果登録APIを呼ぶ
    await this.registCorrectUnits(this.resultDrillId, page, correctInput[0])
    return true
  }
}
