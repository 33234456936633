




import { Component, Vue } from 'vue-property-decorator'
import ImageHorizontal from '@/components/modules/drillsv2/molecules/ImageHorizontal.vue'

@Component({
  components: {
    ImageHorizontal,
  },
})
export default class QuestionImageHorizontal extends Vue {
  private mounted() {
    Vue.prototype.$logger.info('-- QuestionImageHorizontal V2 mounted')
  }
}
