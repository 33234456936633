




import { Component, Vue } from 'vue-property-decorator'
import ImageVertical from '@/components/modules/drillsv2/molecules/ImageVertical.vue'

@Component({
  components: {
    ImageVertical,
  },
})
export default class QuestionImageVertical extends Vue {
  private mounted() {
    Vue.prototype.$logger.info('-- QuestionImageVertical V2 mounted')
  }
}
